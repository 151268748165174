.body {
    overflow: hidden;
  }

  .mic-stopped {
    font-family: "fontello";
    border-radius: 50%;
    background-color: white;
    color: red;
    height: 163px;
    width: 163px;
    box-shadow: inset 0 0 1px 1px #9d302b,
                inset 1px 1px 2px 1px #d95952,
                3px 3px 3px 2px #eee;
    position: relative;
  }
  .icon-mic {
    font-family: "fontello";
    border-radius: 50%;
    background-color: #d2423b;
    color: white;
    height: 163px;
    width: 163px;
    box-shadow: inset 0 0 1px 1px #9d302b,
                inset 1px 1px 2px 1px #d95952,
                3px 3px 3px 2px #eee;
    position: relative;
  }
  
  .icon-mic-overlay {
    content: ' ';
    position: absolute;
    left: -3px;
    top: 0px;
    border: 1px solid #ccc;
    box-shadow: 0 0 1px #ccc;
    border-radius: 50%;
    height: 163px;
    width: 163px;
    opacity: 0;
    z-index: -1;
    animation: ping 1s ease-out;
    animation-iteration-count: infinite;
  }
  
  @keyframes ping {
    0% { transform: scale(0.1, 0.1); opacity: 0.0; }
    50% { opacity: .65; }
    100% { transform: scale(2.5, 2.5); opacity: 0.0; }
  }
  